import Vue from 'vue'
import Vuex from 'vuex'
import translations from './../assets/translations'
import zlogin from './../Auth/zlogin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    savingAll: false,
    viAPI: 'https://bgp.vetimpress.com/api',
    tooltip:{
      text: '',
      show: false,
      durationSeconds: 5
    },
    validForms: {
    },
    udnError: false,
    firstLogin: true,
    questionsUIKeys:{
      // keys that are refreshed depending on another answer (e.g. divide questions)
      "17": 0.17224845489341899,
      "70": 0.17246127899324115,
      "71": 0.12301588554894316,
      "72": 0.7607608051970529,
      "73": 0.10579212264214677,
      "76": 0.40717746677998923,
      "191": 0.8107865506703111,
      "198": 0.8107865506703222,
      "1017": 0.2,
      "1070": 0.3,
      "1072": 0.4,
      "1076": 0.5
    },
    // todo part -> section -> subsection array to speed up search in new bgp
    bgpSubSectionIDs: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,22,23,29,30,31,32],
    bgpBeefSubSectionIDs: [1001,1002,1003,1004,1005,1006,1007,1008,1009,1010,1011,1012,1013,1014,1015,1016,1017,1018,1019,1020, 23, 22, 26, 29, 30, 31, 32],
    foodSafetySubSectionIDs: [21,24,25,26,27,28],
    showErrorDialog: false,
    formValid: false,
    runFormValidation: false,
    invalidItems: {},
    twoBGPSPerFarm: {},
    validBGP: false,
    zlogin: new zlogin(),
    randomUIKey: Math.random(),
    showCompleteDialog: false,
    showSnackBar: false,
    snackBarColour: 'info',
    snackBarText: '',
    finishedBGP: false,
    madeBGPChanges: false,
    assessmentDialog: false,
    addInfoDialog: false,
    evaluateDialog: false,
    foodSafetyDialog: false,
    progress: 0,
    lang: 'Nl',
    //lang: 'En',
    translations,
    title: '',
    loggedIn: false,
    reportLoggedIn: false,
    reportUser: {
    },
    //loggedIn: true,
    farms: {},
    questions: {},
    answeredQuestions: 0,
    numberOfHighLevelQuestions: 0,
    verifier: null,
    challenge: null,
    user: {
      //  id: 1,
      //UDN: '1504413',
      //  Username: 'username',
      //  given_name: 'given name',
      //  family_name: 'family name',
    },
    bgp: {
      details: {},
      ID: null,
      farm: {},
      answers: {
        cowAssessment: [],
        abnormal: [],
      },
      actionPoints: [],
      evaluationPoints: [],
    },
    signWidth: 0,
    setSignWidth: false,
    usageOptions: [
      {
          'AppID': 1,
          'EnDesc': 'very high',
          'NlDesc': 'zeer hoog'
      },
      {
          'AppID': 2,
          'EnDesc': 'high',
          'NlDesc': 'hoog'
      },
      {
          'AppID': 3,
          'EnDesc': 'average',
          'NlDesc': 'gemiddeld'
      },
      {
          'AppID': 4,
          'EnDesc': 'low',
          'NlDesc': 'laag'
      },
       {
          'AppID': 5,
          'EnDesc': 'very low',
          'NlDesc': 'zeer laag'
      }
  ]
  },
  mutations: {
    resetBGP (state) {
      //console.log('resetting BGP$')
      state.bgp = {}
      state.bgp = {
        details: {},
        ID: null,
        farm: {},
        answers: {
          cowAssessment: [],
          abnormal: [],
        },
        actionPoints: [],
        evaluationPoints: [],
      }
    }
  },
  actions: {
    resetBGP(context){
      context.commit('resetBGP')
    }
  },
  modules: {
  },
  getters: {
    getRefreshFormValidation: state => state.runFormValidation
    // getQuestionByID: state => (questionID) => {
    //   console.log(`question id is ${JSON.stringify(questionID, undefined, 2)}`)
    //   for(let part in state.questions){
    //     for(let section in state.questions[part].sections){
    //       for (let subSection in state.questions[part].sections[section].subSections){

    //         let question = state.questions[part].sections[section].subSections[subSection].questions.find((q)=>{
    //           return q.ID == questionID
    //         })
            
    //         if(question != undefined){
    //           console.log(`question id = ${questionID} returning ${JSON.stringify(questionID)}`)
    //           return question
    //         }
          
    //     }
    //   }
    //   }
    // }
  }
})

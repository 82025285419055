<template>
    <span>
        <v-card>
            <v-card-title> 
                <h3> {{$store.state.translations[$store.state.lang].settings}} </h3>
            </v-card-title>

            <v-col>

                <v-divider/>

                <v-row justify="center" class="pa-5">

                    <v-btn
                        @click="$store.state.lang = $store.state.lang == 'Nl' ? 'En' : 'Nl';
                            $vuetify.lang.current = $store.state.lang == 'Nl' ? 'nl' : 'en';"
                            text
                            color="primary"
                    > 
                        <v-icon>
                            language
                        </v-icon>
                    
                        {{$store.state.translations[$store.state.lang].changeLanguageTo}} {{$store.state.lang=='Nl' ? 'En' : 'Nl' }}     
                    </v-btn>

                </v-row>

                <v-divider/>

                <v-row justify="center" class="pa-5">
                <h3> {{$store.state.translations[$store.state.lang].medirundLoginDetails}} </h3>
                </v-row>

                <v-progress-circular color="primary" size="20px" v-if="loading">
                </v-progress-circular>

                <span v-else>
                    <v-row justify="center">

                        <v-col cols="8">
                            <v-text-field
                                v-model="$store.state.user.MedirundUser"
                                :label="$store.state.translations[$store.state.lang].medirundUsername"
                            ></v-text-field>
                        </v-col>

                    </v-row> 

                    <v-row justify="center">

                        <v-col cols="8">
                            <v-btn v-if="(!changePassword)" color="primary" text @click="changePassword=true; $store.state.user.MedirundPass = ''">
                                stel een wachtwoord in
                            </v-btn>

                            <v-text-field
                                v-model="$store.state.user.MedirundPass"
                                :label="$store.state.translations[$store.state.lang].medirundPassword"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                v-if="changePassword"
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row justify="center">
                            <v-btn color="primary"
                                @click="saveMedirund()" small dark text
                            >
                            {{$store.state.translations[$store.state.lang].save}}
                            <v-icon color="primary" dark>save</v-icon>
                        </v-btn>
                    </v-row>   
                </span>       

            </v-col>

        </v-card>
        <v-col>
                <v-row justify="center">
                    <p>Versie: 2.18 (2022-04-25)</p>
                </v-row>  
        </v-col>
    </span>
</template>
<script>
const axios = require('axios')

export default {

    data () {
        return {
            loading: false,
            show1: false,
            changePassword: false,
        }
    },
    methods: {
        saveMedirund: async function(){
            try {
                 this.loading = true
                 let headers = await this.$store.state.zlogin.getHeaders()
                 await axios.post('/editAllInObject/vetUserModel', [{
                     'UDN': this.$store.state.user.UDN,
                     'MedirundUser': this.$store.state.user.MedirundUser,
                     'MedirundPass': this.$store.state.user.MedirundPass
                 }], headers)
                 this.loading = false
                 this.$router.push('/')
            } catch (e) {
                console.log(e)
                this.$router.push('/')
            }
        }
    },
    created: function(){
        console.log(`settings created user is ${JSON.stringify(this.$store.state.user, undefined, 2)}`)
    }
    
}

</script>
<template>

    <v-col cols="12" class="pa-0 ma-0">
        <v-row v-if="!loadedData" justify="center" align="center" class="pa-0 ma-0">
            <v-progress-circular
                color="primary"
                size=100
                indeterminate
            ></v-progress-circular>
        </v-row>


        <v-card v-else v-bind:key="cardUIKey">

            <v-row justify="center" v-if="$store.state.user.MedirundPass == null || $store.state.user.MedirundPass.length==0 || $store.state.user.MedirundUser == null || $store.state.user.MedirundUser.length==0">
                <v-chip
                        class="ma-2"
                        color="primary"
                        outlined
                        @click="$router.push('/settings')"
                    >
                    <v-icon left>
                        mdi-wrench
                    </v-icon>
                    {{$store.state.translations[$store.state.lang].addMedirund}}
                </v-chip>
            </v-row>

            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="getData()"
                :search="search"
                calculate-widths
                mobile-breakpoint="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                >
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                    </v-alert>
                </template>

                <template v-slot:item.UBN="{ item }">
                    <p class="small"> {{item.UBN}} </p>
                </template>

                 <template v-slot:item.farm="{ item }">
                    <p> {{item.farm}} </p>
                </template>

                <template v-slot:item.bgpExpires="{ item }">

                    <span v-for="form in item.completeBGP" :key="form.ID">
                       <v-col>

                           <v-btn 
                                text color="primary" x-small 
                                @click="downloadFile(form.BGPPDF)"
                                v-if="form.BGPPDF != null && form.BGPPDF.length>0"
                            >
                                BGP ({{moment(form.Completed).format("YYYY-MM-DD")}}) 
                            </v-btn>
                       </v-col>
                    </span>
                    
                    <v-divider/>

                    <span v-if="item.offlineBGP.length>0">
                        <span v-for="bgp in item.offlineBGP" v-bind:key="bgp.ID">
                            <h4> {{item.offlineBGP.length}} items die wachten op synchronisatie </h4>
                        </span>
                    </span>

                    <br>

                    <v-col>
                         <v-row justify="center">
                            <p v-if="moment(item.bgpExpires).diff(moment(null),'days') < 1" class="redText">
                                {{$store.state.translations[$store.state.lang].dueOn}} {{moment(item.bgpExpires).format("YYYY-MM-DD")}}
                            </p>
                            <p v-else class="greenText">
                                {{$store.state.translations[$store.state.lang].dueOn}} {{moment(item.bgpExpires).format("YYYY-MM-DD")}}
                            </p>
                        </v-row>
                        <v-row justify="center">
                            <!-- <v-col v-if="item.bgp">
                                <v-row class = "ma-0 pa-0">
                                    <v-btn class="ma-2"  @click="resumeBGP(item, 'bgp')" color="info" outlined x-small>
                                        {{$store.state.translations[$store.state.lang].resumeBGP}} 
                                        ({{item.bgp.Type=='bgp' ? $store.state.translations[$store.state.lang].dairy : $store.state.translations[$store.state.lang].beef }})   
                                    </v-btn>
                                    <v-btn icon class="ma-2"  @click="deleteBGP(item)" color="info" x-small>
                                        <v-icon color="error"> delete </v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col> -->

                            <v-menu offset-y v-if="item.bgp">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="info"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined x-small
                                        class="ma-2"
                                    >
                                       {{item.bgp.Type=='bgp' ? $store.state.translations[$store.state.lang].dairy : $store.state.translations[$store.state.lang].beef }}
                                         BGP
                                        <v-icon
                                            right
                                            dark
                                            x-small
                                        >
                                            mdi-arrow-down
                                        </v-icon> 

                                    </v-btn>
                                </template>
                                <v-list rounded nav dense>
                                    <v-list-item
                                      :key="1"
                                       @click="resumeBGP(item, 'bgp')"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="info">label_important</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>{{$store.state.translations[$store.state.lang].resume}}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                      :key="2"
                                      @click="showDelete(item, 'bgp')"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="red">delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>{{$store.state.translations[$store.state.lang].delete}}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <v-menu offset-y v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined x-small
                                        class="ma-2"
                                    >
                                        {{$store.state.translations[$store.state.lang].newBGP}}

                                        <v-icon
                                            right
                                            dark
                                            x-small
                                        >
                                            mdi-arrow-down
                                        </v-icon> 

                                    </v-btn>
                                </template>
                                <v-list rounded nav dense>
                                    <v-list-item
                                      :key="1"
                                      @click="newBGP(item, 'bgp')"
                                    >
                                        <v-list-item-title>{{$store.state.translations[$store.state.lang].dairy}}</v-list-item-title>
                                    </v-list-item>
                                    <!-- <v-list-item
                                      :key="2"
                                      @click="newBGP(item, 'beefBGP')"
                                    >
                                        <v-list-item-title>{{$store.state.translations[$store.state.lang].beef}}</v-list-item-title>
                                    </v-list-item> -->
                                </v-list>
                            </v-menu>

                        </v-row>
                    </v-col>

                     <br>
                </template>

                <template v-slot:item.foodSafetyExpires="{ item }">

                    <span v-for="form in item.completeFoodSafety" :key="form.ID">
                        <v-col>
                    

                            <v-btn 
                                text color="primary" x-small 
                                @click="downloadFile(form.FoodSafetyPDF)"
                                v-if="form.FoodSafetyPDF != null && form.FoodSafetyPDF.length>0"
                            >
                                {{$store.state.translations[$store.state.lang].foodSafety}} ({{moment(form.Completed).format("YYYY-MM-DD")}}) 
                            </v-btn>

                       </v-col>
                     
                    </span>

                    <v-divider/>

                    <span v-if="item.offlineBGP.length>0">
                        <span v-for="bgp in item.offlineBGP" v-bind:key="bgp.ID">
                            <h4> {{item.offlineBGP.length}} items die wachten op synchronisatie </h4>
                        </span>
                    </span>

                    <br>

                    <v-col>
                        <v-row justify="center">
                            <p v-if="moment(item.foodSafetyExpires).diff(moment(null),'days') < 1" class="redText">
                                {{$store.state.translations[$store.state.lang].dueOn}} {{moment(item.foodSafetyExpires).format("YYYY-MM-DD")}}
                            </p>
                            <p v-else class="greenText">
                                {{$store.state.translations[$store.state.lang].dueOn}} {{moment(item.foodSafetyExpires).format("YYYY-MM-DD")}}
                            </p>
                        </v-row>
                        <v-row justify="center">
                            <v-menu offset-y v-if="item.foodSafety">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="info"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined x-small
                                        class="ma-2"
                                    >
                                       {{$store.state.translations[$store.state.lang].newFoodSafety}}
                                        
                                        <v-icon
                                            right
                                            dark
                                            x-small
                                        >
                                            mdi-arrow-down
                                        </v-icon> 

                                    </v-btn>
                                </template>
                                <v-list rounded nav dense>
                                    <v-list-item
                                      :key="1"
                                      @click="resumeBGP(item, 'foodSafety')"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="info">label_important</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title >{{$store.state.translations[$store.state.lang].resume}}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                      :key="2"
                                      @click="showDelete(item, 'foodSafety')"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="red">delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>{{$store.state.translations[$store.state.lang].delete}}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-btn class="ma-2" v-else @click="newBGP(item, 'foodSafety')" color="primary" outlined x-small>
                                {{$store.state.translations[$store.state.lang].newFoodSafety}}   
                            </v-btn>
                        </v-row>
                    </v-col>

                     <br>
                </template>

            </v-data-table>
        </v-card>

        <v-dialog v-model="showDeleteDialog" max-width="500px" v-if="Object.keys(itemToDelete).length>0"
                :key="itemToDelete.UBN + Math.random()"
            >
          <v-card>
            <v-card-title class="headline" v-if="Object.keys(itemToDelete).length>0">
               <!-- {{$store.state.translations[$store.state.lang].delete}} {{getBGPTypeText()}} -->
               Melkvee BGP verwijderen
            </v-card-title>
            <v-card-subtitle v-if="Object.keys(itemToDelete).length>0">
                {{$store.state.translations[$store.state.lang].areYouSureYouWantToDelete}} <h3>{{getBGPTypeText()}}</h3> {{$store.state.translations[$store.state.lang].forUBN}} <h3> {{itemToDelete.UBN}} </h3> wilt verwijderen?
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" text @click="showDeleteDialog=false">Annuleren</v-btn>
              <v-btn color="error" text @click="deleteBGP()">Bevestigen</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-col>

</template>

<script>
const axios = require('axios')
const moment = require('moment')
const _ = require('lodash')
import serverMixins from './../helpers/ServerOperations'
import consts from './../helpers/Consts'


export default {
    mixins: [serverMixins],
    data () {
        return {
            cardUIKey: Math.random(),
            itemToDelete: {},
            itemTypeToDelete: '',
            showDeleteDialog: false,
            deleteConfirmed: false,
            offlineBGPs: {},
            search: '',
            loadedData: false,
            sortBy: 'bgpExpires',
            sortDesc: false,
            headers: [
                { text: 'UBN', 
                    value: 'UBN',
                    width: 10 
                },
                { text: this.$store.state.translations[this.$store.state.lang].farm, value: 'farm' },
                // { text: this.$store.state.translations[this.$store.state.lang].forms, value: 'latestForms' },
                { text: this.$store.state.translations[this.$store.state.lang].bgp, value: 'bgpExpires' },
                { text: this.$store.state.translations[this.$store.state.lang].foodSafety, value: 'foodSafetyExpires' },
            ]
        }
    },
    computed: {

    },
    mounted() {
        if (localStorage.getItem('offlineBGPs')) {
            try {
                this.offlineBGPs = JSON.parse(localStorage.getItem('offlineBGPs'));
            } catch(e) {
                localStorage.removeItem('offlineBGPs');
            }
        } else {
            localStorage.setItem('offlineBGPs', JSON.stringify({}))
        }
    },
    methods: {
        getBGPTypeText: function(){
            try {
                if (this.itemTypeToDelete == 'bgp'){
                    switch (this.itemToDelete.bgp.Type) {
                        case consts.bgpBeefKey:
                            return `${this.$store.state.translations[this.$store.state.lang].beef} BGP`
                        default:
                            return `${this.$store.state.translations[this.$store.state.lang].dairy} BGP`
                    }

                } else if (this.itemTypeToDelete == consts.bgpFoodSafetyKey){
                    return `${this.$store.state.translations[this.$store.state.lang].foodSafety}`
                } else {
                    return ''
                }
             
            } catch (e) {
                console.log(e)
                return ''
            }
            
        },
        deleteBGP: async function(){
            try {
                let headers = await this.$store.state.zlogin.getHeaders()
                let itemToDeleteUUID = this.itemTypeToDelete == consts.bgpFoodSafetyKey ? this.itemToDelete.foodSafety.UUID : this.itemToDelete.bgp.UUID
                await axios.delete(`${consts[consts.env].apiURL}/deleteBGP/${itemToDeleteUUID}`, headers)
                this.deleteConfirmed = false
                this.itemTypeToDelete = ''
                this.itemToDelete = {}
                this.showDeleteDialog = false
                this.loadedData = false
                await this.getFarms()
                this.cardUIKey = Math.random()
                this.loadedData = true
            } catch (e) {
                console.log(e)
                this.$store.state.snackBarText = `${this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection}`
                this.$store.state.snackBarColour = 'error'
                this.$store.state.showSnackBar = true
                this.showDeleteDialog = false
                this.loadedData = true
            }
        },
        showDelete: function(item, type){
            console.log(`deleting item ${JSON.stringify(item, undefined, 2)} type ${type}`)
            this.deleteConfirmed = false
            this.itemTypeToDelete = type
            this.itemToDelete = item
            this.showDeleteDialog = true
        },
        addBGPToOffline: function(bgp){
            try {
                if(this.offlineBGPs[bgp.UBN]){
                    this.offlineBGPs[bgp.UBN].push(bgp)
                } else {
                    this.offlineBGPs[bgp.UBN] = [
                        bgp
                    ]
                } 
                localStorage.setItem('offlineBGPs', JSON.stringify(this.offlineBGPs))
            } catch (e) {
                //console.log(`error adding bgp ${JSON.stringify(bgp, undefined, 2)} to local storage`)
            }
        },
        moment: function(pDate=null){
            return pDate != null ? moment(pDate) : moment()
        },
        downloadFile: async function(fileName){
            let headers = await this.$store.state.zlogin.getHeaders()

            axios({
                url: `${consts[consts.env].apiURL}/downloadFile/${fileName}`,
                method: 'GET',
                responseType: 'blob',
                headers: headers.headers,
            }).then((response)=>{
                ////console.log(`response is ${JSON.stringify(response, undefined, 2)}`)
                var blob = new Blob([response]);

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            }).catch ((e)=>{
                //console.log(e)
            })          
        },
        resumeBGP: async function(item, pType){
            //console.log(`resuming BGP with item ${JSON.stringify(item, undefined, 2)}`)
            try {
                let headers = await this.$store.state.zlogin.getHeaders()

                this.loadedData = false

                this.$store.state.bgp.ID = item[pType].ID
                this.$store.state.bgp.UUID = item[pType].UUID
                this.$store.state.bgp.farm = {...item}

                let bgp = await axios.get(`/getObject/bgpModel/UUID/${item[pType].UUID}`, headers)
                this.$store.state.bgp.details = bgp[0]
               
                await this.refreshFromServer()

                ////console.log(`store.state answers are now ${JSON.stringify(this.$store.state.bgp.answers)}`)
                if(pType == 'bgp'){
                    //this.$store.state.title = this.$store.state.translations[this.$store.state.lang].newBGP
                    try {
                      this.$store.state.title = this.$store.state.bgp.farm.farmName
                    } catch (e) {
                        //console.log(e)
                    }
                } else {
                    //this.$store.state.title = this.$store.state.translations[this.$store.state.lang].foodSafety
                    try {
                        this.$store.state.title = this.$store.state.bgp.farm.farmName
                    } catch (e) {
                        //console.log(e)
                    }
                }

                this.loadedData = true
                ////console.log(`store bgp is ${JSON.stringify(this.$store.state.bgp, undefined, 2)}`)
                this.$router.push('/bgp')
            } catch (e) {
                console.log(e)
                this.loadedData = true
                this.$store.state.snackBarText = this.$store.state.translations[this.$store.state.lang].errorResuming
                this.$store.state.snackBarColour = 'error'
                this.$store.state.showSnackBar = true
            }
        },
        newBGP: async function(item, pType){
            console.log(`creating a new BGP for farm id ${item.UBN} type is ${pType}`)
            let headers = await this.$store.state.zlogin.getHeaders()
            this.loadedData = false
            try {
                let bgpModel = {
                        "ID": null,
                        "UUID": this.generateID(),
                        "UDN": this.$store.state.user.UDN,
                        "UBN": item.UBN,
                        "Status": "draft",
                        "Completed": null,
                        "Expires": null,
                        "BGPPDF": '',
                        "FoodSafetyPDF": '',
                        'createdAt': moment().format("YYYY-MM-DDTHH:mm"),
                        'Type': pType
                    }

                try {
                    let bgpResult = await axios.post('/createObject/bgpModel',[
                        bgpModel
                    ], headers)
                } catch (e) {
                    console.log(e)
                    // throw  error here
                    throw 'error creating new bgp'
                    // add bgp to indexed db
                    // let offline = this.checkIfOfflineAndShowCacheSnackbar()
                    // if (offline) {
                    //     this.addBGPToOffline(bgpModel)
                    // }
                }

                               
                this.$store.state.bgp.ID = bgpModel.ID
                this.$store.state.bgp.UUID = bgpModel.UUID
                this.$store.state.bgp.details = bgpModel
                this.$store.state.bgp.farm = {...item}

                //console.log(`user is ${JSON.stringify(this.$store.state.user, undefined, 2)}`)

                this.$store.state.title = this.$store.state.translations[this.$store.state.lang].newBGP
                
                // pre-populate the answer to some questions
                this.getPrePopulatedDataAndSetQuestions(item)

                if(pType == consts.bgpFoodSafetyKey){

                    this.$store.state.title = this.$store.state.translations[this.$store.state.lang].foodSafety
                     // name of vet
                    this.$store.state.bgp.answers["164"] = {'Value': this.$store.state.user.given_name + ' ' + this.$store.state.user.family_name}
                    // UDN
                    this.$store.state.bgp.answers["165"] = {'Value': this.$store.state.user.UDN}
                    // Name of farmer
                    this.$store.state.bgp.answers["166"] = {'Value': item.farmName}
                    // UBN
                    this.$store.state.bgp.answers["167"] = {'Value': item.UBN}

                } else {
                    this.getPrePopulatedDataAndSetQuestions(item)
                }

                try {
                    this.$store.state.title = this.$store.state.bgp.farm.farmName
                } catch (e) {
                    console.log(e)
                }
                    
                this.saveAll()

                this.loadedData = true

                //console.log(`store bgp is ${JSON.stringify(this.$store.state.bgp, undefined, 2)}`)
                this.$router.push('/bgp')
            } catch (e) {
                console.log(e)
                this.$store.state.snackBarText = `${this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection}`
                this.$store.state.snackBarColour = 'error'
                this.$store.state.showSnackBar = true
                this.loadedData = true
            }
                
        },
        getPrePopulatedDataAndSetQuestions: async function(item){
            let headers = await this.$store.state.zlogin.getHeaders()
            let offset = 0;

            switch(this.$store.state.bgp.details.Type){
                case consts.bgpBeefKey:
                    offset = 1000
                    break;
                default:
                    offset = 0
                    break;
            }

            let prevBGP = null
            try {
                prevBGP = await axios.get(`automatedQuestionData/${item.UBN}/${this.$store.state.bgp.details.Type}`, headers)
                //console.log(`prevBGP data is ${JSON.stringify(prevBGP, undefined, 2)}`)
            } catch (e) {
                console.log(e)
            }

            // name of vet
            this.$store.state.bgp.answers[(1 + offset).toString()] = {'Value': this.$store.state.user.given_name + ' ' + this.$store.state.user.family_name}
            // UDN
            this.$store.state.bgp.answers[(2 + offset).toString()] = {'Value': this.$store.state.user.UDN}
            // Name of farmer
            this.$store.state.bgp.answers[(3 + offset).toString()] = {'Value': item.farmName}
            // UBN
            this.$store.state.bgp.answers[(4 + offset).toString()] = {'Value': item.UBN}

            //todo check this works with the offsets
            if (prevBGP && prevBGP.prevBGPAnswers){
                for(let questionID in prevBGP.prevBGPAnswers){
                    //console.log(`questionID is ${questionID}`)
                    // this is just questionID since they should be of the same type
                    this.$store.state.bgp.answers[questionID] = {
                        'Value': '',
                        'PrevBGPValue': prevBGP.prevBGPAnswers[questionID].Value,
                        'TargetValue': '',
                        'Usage': ''
                    }
                }
            }

             // get prepopulated data
            try {
                let prePopData = await axios.get(`bgpInfo/${this.$store.state.user.UDN}/${item.UBN}`, headers)
                //console.log(`pre populated questions are ${JSON.stringify(prePopData, undefined, 2)}`)
                // DDDAf questions (medirund)
                let dddaf = ['109', '110', '111', '112']

                dddaf.forEach((questionID)=>{
                    if(prePopData[`Q${questionID}`] != null){
                        this.$store.state.bgp.answers[(parseInt(questionID) + offset).toString()] = {
                            'Value': prePopData[`Q${questionID}`],
                            'PrevBGPValue': '',
                            'TargetValue': '',
                            'Usage': ''
                        }
                    }
                })

                // RVO
                let rvo = ['6', '7', '8', '187', '15', '16', '17', '18', '19']
                rvo.forEach((questionID)=>{
                    if(prePopData[`Q${questionID}`] != null){
                        this.$store.state.bgp.answers[(parseInt(questionID) + offset).toString()] = {
                            'Value': prePopData[`Q${questionID}`],
                            'PrevBGPValue': '',
                            'TargetValue': '',
                            'Usage': ''
                        }
                    }
                })
                
            } catch (e) {
                console.log(e)
            }


        },
        getData: function(){
            try {
                let res = []
                // duplication in below res to enable search
                this.$store.state.farms.forEach((farm)=>{
                    let remainingOfflineBGP = []
                     // if there is a bgp on the server with the same id as a bgp in 'offlineBGPs'(local storage) the BGP has been successfully transmitted to server.
                    // prune from local storage
                    // try {
                    //     //console.log(`this.offlineBGPs[farm.UBN] ${this.offlineBGPs[farm.UBN]}`)
                    //     if(Object.keys(this.offlineBGPs).length>0 && this.offlineBGPs[farm.UBN] != undefined){
                    //         let farmBGPIDs = farm.BGPs.map((bgp)=>{
                    //             return bgp.ID
                    //         })

                    //         let temp = this.offlineBGPs[farm.UBN].filter((bgp)=>{
                    //             return (! farmBGPIDs.includes(bgp.ID))
                    //         })

                    //         this.offlineBGPs[farm.UBN] = JSON.parse(JSON.stringify(temp))

                    //         if (this.offlineBGPs[farm.UBN] && this.offlineBGPs[farm.UBN].length>0){
                    //             remainingOfflineBGP = this.offlineBGPs[farm.UBN]
                    //         }

                    //         // write out
                    //         //console.log(`temp is ${JSON.stringify(this.offlineBGPs[farm.UBN], undefined, 2)}`)

                    //         localStorage.setItem('offlineBGPs', JSON.stringify(this.offlineBGPs))    
                    //     }
                    // } catch (e) {
                    //     //console.log(e)
                    // }
                    let bgpExpires = null
                    let foodSafetyExpires = null
                    //console.log(`full list of BGPs are ${JSON.stringify(farm.BGPs, undefined, 2)}`)

                    try {
                        let bgps = farm.BGPs.filter((bgp)=>{
                            return bgp.Status == 'complete' && bgp.Type == consts.bgpDairyKey
                        })
                        
                        // sort by expiry date ASC
                        bgps.sort((a,b)=>{
                            return moment(b.Expires).valueOf() - moment(a.Expires).valueOf() 
                        })
                        
                        // first item of sorted array 
                        bgpExpires = moment(bgps[0].Expires).valueOf()
                    } catch (e){
                        console.log(e)
                    }

                    try {
                        let foodSafety = farm.BGPs.filter((bgp)=>{
                            return bgp.Status == 'complete' && bgp.Type == consts.bgpFoodSafetyKey
                        })
                        
                        // sort by expiry date ASC
                        foodSafety.sort((a,b)=>{
                            return moment(b.Expires).valueOf() - moment(a.Expires).valueOf() 
                        })
                        
                        // first item of sorted array
                        foodSafetyExpires = moment(foodSafety[0].Expires).valueOf()
                    } catch (e){
                        console.log(e)
                    }
                    
                    res.push({
                        UBN: farm.UBN,
                        farmName: farm.Name,
                        farm: `${farm.Name}: ${farm.Address1 != null ? farm.Address1 + ', ' : ''}
                                        ${farm.Address2 != null ? farm.Address2 + ', ' : ''}
                                        ${farm.Town != null ? farm.Town : ''} `,
                        latestForms: null,
                        bgpExpires: bgpExpires,
                        foodSafetyExpires: foodSafetyExpires,
                        bgp: farm.BGPs.find((bgp)=>{
                            return bgp.Status == 'draft' && (bgp.Type == consts.bgpDairyKey || bgp.Type == consts.bgpBeefKey) && moment().diff(moment(bgp.createdAt), 'days') < 365
                        }),
                        completeBGP: farm.BGPs.filter((bgp)=>{
                            return bgp.Status == 'complete' && (bgp.Type == consts.bgpDairyKey || bgp.Type == consts.bgpBeefKey)
                        }),
                        foodSafety: farm.BGPs.find((bgp)=>{
                            return bgp.Status == 'draft' && bgp.Type == consts.bgpFoodSafetyKey && moment().diff(moment(bgp.createdAt), 'days') < 365
                        }),
                        completeFoodSafety: farm.BGPs.filter((bgp)=>{
                            return bgp.Status == 'complete' && bgp.Type == consts.bgpFoodSafetyKey
                        }),
                        offlineBGP: remainingOfflineBGP
                    })
                })
                //console.log(`res is ${JSON.stringify(res, undefined, 2)}`)
                return res
            } catch (e) {
                console.log(e)
                return []
            }
        },
        getFarms: async function(){
          
            try {
                let headers = await this.$store.state.zlogin.getHeaders()
                let result = await axios.get(`/farms/${this.$store.state.user.UDN}`, headers)
                this.$store.state.farms = result.Farms
                //console.log(`farms is ${//console.log(`farms is ${JSON.stringify(this.$store.state.farms)}`)}`)
            } catch (e) {
                //console.log(e)
                this.checkIfOfflineAndShowCacheSnackbar()
            }
         
        }
    },
    created: async function(){
        this.loadedData = false

        try {
            let headers = await this.$store.state.zlogin.getHeaders()

            try {
                // get medirund username/pass
                let vetUser = await axios.get(`${consts[consts.env].apiURL}/getOrAddVetUser/${this.$store.state.user.UDN}`, headers)
                this.$store.state.user.MedirundPass = vetUser.MedirundPass
                this.$store.state.user.MedirundUser = vetUser.MedirundUser
                //console.log(`user after getOrAddVetUser is ${JSON.stringify(this.$store.state.user, undefined, 2)}`)
            } catch (e) {
                console.log(e)
            }

            this.getFarms()

             try {
                 let temp = await axios.get('/sectionDetails', headers)
                ////console.log(`temp is ${JSON.stringify(temp, undefined, 2)}`)

                this.$store.state.questions = temp.result
                this.$store.state.numberOfHighLevelQuestions = temp.numQuestions
            } catch (e) {
                //console.log(e)
                this.checkIfOfflineAndShowCacheSnackbar()
            }

            // reset state variables
            try {
                this.$store.state.formValid = false
                this.$store.state.runFormValidation = false
                this.$store.state.validForms = {}
            } catch (e) {
                console.log(e)
            }
            
        } catch (e) {
            //console.log(e)
            this.loadedData = true
            this.$store.state.snackBarText = 'Error retrieving list of farms. Please try again.'
            this.$store.state.snackBarColour = 'error'
            this.$store.state.showSnackBar = true
            //this.$router.push('/login')
        }

        this.loadedData = true
    },
    beforeCreate: function(){
        this.$store.state.title = 'BGP/Farms'
    }
}
</script>